import { destroyDictionary, fetchDictionaries } from '@/api/site/site'
import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const loading = ref(false)

  const tableColumns = [
    {
      text: 'ID',
      value: 'id',
    },
    {
      text: 'Type',
      value: 'category',
    },
    {
      text: 'Key',
      value: 'key',
    },
    {
      text: 'Translation',
      value: 'value',
    },
    {
      text: 'Distributor',
      value: 'distributor',
    },
    {
      text: 'Notes',
      value: 'notes',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = ref(
    cached[0] || {
      sortBy: ['category'],
      sortDesc: [false],
      itemsPerPage: 30,
    },
  )

  // Filter
  const filters = ref({
    name: '',
    category_in: [],
    distributor_id: undefined,
    root_only: true,
  })

  const loadData = async () => {
    loading.value = true

    try {
      const { data } = await fetchDictionaries(useTableOptions(options.value, parseFilters()))

      if (data.status === 'success') {
        tableItems.value = data.data.records
        tableTotal.value = data.data.pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters.value)
      .filter(key => filters.value[key])
      .reduce(
        (a, key) => ({
          ...a,
          [key]: filters.value[key],
        }),
        {},
      )

  const deleteDictionary = obj =>
    destroyDictionary(obj.id)
      .then(() => {
        useNotifySuccess({
          content: 'Delete Success',
        })
        loadData()
      })
      .catch(useNotifyErrors)

  watch([options], () => {
    loadData()
  })

  watch(filters, debounce(loadData, 300))

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,

    options,
    filters,

    loadData,
    deleteDictionary,
  }
}
